import React, { useEffect } from "react";
import useFetch, { CachePolicies } from "use-http";
import { useLocation } from "wouter";
import { useIdentifiedSupportConversation } from "../../lib/utils";

export const LoadHubSpotChat = (props: { path: string }) => {
  const { post: identifySupportConversation } = useFetch(props.path, {
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const [location] = useLocation();

  useIdentifiedSupportConversation(
    async () => {
      const result = (await identifySupportConversation({})) as {
        identifySupportConversation?: { identificationEmail: string; identificationToken: string };
      };

      if (result && result.identifySupportConversation) {
        return {
          email: result.identifySupportConversation.identificationEmail,
          token: result.identifySupportConversation.identificationToken,
        };
      }
    },
    { loadImmediately: true }
  );

  useEffect(() => {
    const status = window.HubSpotConversations?.widget.status();

    if (status && status.loaded) {
      window.HubSpotConversations?.widget.refresh();
    }
  }, [location]);

  return <div />;
};
