import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const DocsInfoIcon = (props: React.ComponentProps<typeof StyledSvg> & { color: string }) => {
  const [_css, $theme] = useStyletron();
  return (
    <StyledSvg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33203 8.00065C1.33203 4.32465 4.3227 1.33398 7.9987 1.33398C11.6747 1.33398 14.6654 4.32465 14.6654 8.00065C14.6654 11.6767 11.6747 14.6673 7.9987 14.6673C6.9377 14.6673 5.88603 14.4107 4.9437 13.9237L2.39003 14.6367C2.31636 14.6573 2.24136 14.6673 2.1667 14.6673C1.94803 14.6673 1.7347 14.5817 1.57636 14.4233C1.36403 14.211 1.28236 13.8993 1.36303 13.6103L2.07603 11.058C1.5887 10.115 1.33203 9.06265 1.33203 8.00065ZM8.00065 5.83333C7.63232 5.83333 7.33398 5.535 7.33398 5.16667C7.33398 4.79833 7.63232 4.5 8.00065 4.5C8.36898 4.5 8.66732 4.79833 8.66732 5.16667C8.66732 5.535 8.36898 5.83333 8.00065 5.83333ZM8.50065 11C8.50065 11.276 8.27665 11.5 8.00065 11.5C7.72465 11.5 7.50065 11.276 7.50065 11V7.33333C7.50065 7.05733 7.72465 6.83333 8.00065 6.83333C8.27665 6.83333 8.50065 7.05733 8.50065 7.33333V11Z"
        fill={props.color ?? $theme.colors.cyan500}
      />
    </StyledSvg>
  );
};
