/* eslint-disable @typescript-eslint/no-empty-object-type */
import { createDarkTheme, createTheme } from "baseui";
import type { Theme, ThemePrimitives, Typography } from "baseui/theme";
import { merge } from "lodash";
import type { StyleObject } from "styletron-standard";
import type { DeepPartial } from "ts-essentials";
import { gadgetColorsDark, gadgetColorsLight, gadgetShadows, getPrimitiveColors, productTypography } from "./primitives";
import { generateTypography } from "./utils";

// TYPES //////////////////////////////////////////////////////////////////////
export type GadgetColors = { [k in keyof typeof gadgetColorsLight]: string };
type GadgetShadows = { [k in keyof typeof gadgetShadows]: string };
type GadgetTypography = { [k in keyof typeof productTypography]: StyleObject };
type GadgetBorders = {
  // BaseUI supports configuring border radius for almost any component, except tables...
  // So let's define this property ourself and use it wherever we use tables
  tableBorderRadius: string;
};

/* eslint-disable @typescript-eslint/no-empty-object-type */
declare module "baseui/theme" {
  interface Colors extends GadgetColors {
    // alpha color scale that changes based on production or not
    themedAlpha50: string;
    themedAlpha100: string;
    themedAlpha200: string;
    themedAlpha300: string;
    themedAlpha400: string;
    themedAlpha500: string;
    themedAlpha600: string;
    themedAlpha700: string;
    themedPrimary50: string;
    themedPrimary100: string;
    themedPrimary200: string;
    themedPrimary300: string;
    themedPrimary400: string;
    themedPrimary500: string;
    themedPrimary600: string;
    themedPrimary700: string;

    // Gadget-specific semantic colors
    backgroundQuaternary: string;
    backgroundQuinary: string;
    backgroundInverseTertiary: string;
    backgroundInfo: string;
    backgroundSubtleBlue: string;
    contentSecondaryOriginal: string;
    contentInfo: string;
    contentDevelopment: string;
    contentLinkText: string;
    contentLinkHover: string;
    contentLinkActive: string;
    backgroundDevelopment: string;
    contentProduction: string;
    backgroundProduction: string;
    contentDisabledDevelopment: string;
    borderInfo: string;

    contentEnvironmentSettingsCallout: string;
    backgroundEnvironmentSettingsCallout: string;
  }

  interface Lighting extends GadgetShadows {}
  interface Typography extends GadgetTypography {
    contentLink: {
      textDecoration: string;
    };
  }
  interface Borders extends GadgetBorders {}
}

export const monoFontFamily = `Inconsolata, Monaco, monospace`;
export const proseFamily = `Inter, -apple-system, BlinkMacSystemFont, sans-serif`;

export const editOverrides: {
  name: "edit";
  borders: {
    radius200: string;
    radius300: string;
    radius400: string;
    radius500: string;
    checkboxBorderRadius: string;
  };
  grid: {
    columns: number[];
    gutters: number[];
    margins: number[];
  };
  lighting: {
    shadow400: string;
    shadow700: string;
  };
  typography: DeepPartial<Typography>;
} = {
  name: "edit",
  borders: {
    radius200: "4px",
    radius300: "8px",
    radius400: "10px",
    radius500: "12px",
    checkboxBorderRadius: "4px",
  },
  grid: {
    columns: [4, 8, 12],
    gutters: [16, 16, 16],
    margins: [0, 12, 24],
  },
  lighting: {
    ...gadgetShadows,
    shadow400: "0px 1px 0px rgba(0, 0, 0, 0.07);",
    shadow700: "-2px 2px 0px rgba(0, 0, 0, 0.07);",
  },
  typography: generateTypography(
    {
      primaryFamily: monoFontFamily,
      monoFamily: monoFontFamily,
    },
    productTypography
  ),
} as const;

export type EditOverrides = typeof editOverrides;

enum SemanticType {
  Development,
  Production,
}

const getSemanticOverrides = (type: SemanticType, colors: GadgetColors) => {
  const developmentOverrides = {
    colors: {
      ...colors,

      themedAlpha50: colors.alpha50,
      themedAlpha100: colors.alpha100,
      themedAlpha200: colors.alpha200,
      themedAlpha300: colors.alpha300,
      themedAlpha400: colors.alpha400,
      themedAlpha500: colors.alpha500,
      themedAlpha600: colors.alpha600,
      themedAlpha700: colors.alpha700,

      themedPrimary50: colors.primary50,
      themedPrimary100: colors.primary100,
      themedPrimary200: colors.primary200,
      themedPrimary300: colors.primary300,
      themedPrimary400: colors.primary400,
      themedPrimary500: colors.primary500,
      themedPrimary600: colors.primary600,
      themedPrimary700: colors.primary700,

      accent100: colors.electricBlue100,
      accent200: colors.electricBlue200,
      accent300: colors.electricBlue300,
      accent400: colors.electricBlue400,
      accent500: colors.electricBlue500,
      accent600: colors.electricBlue600,
      accent700: colors.electricBlue700,

      contentSecondary: colors.primary500, // "Subdued Text" in Figma designs
      contentSecondaryOriginal: colors.primary600, // Original Base UI secondary
      contentAccent: colors.electricBlue500,
      contentInfo: colors.cyan700,
      contentNegative: colors.red500,
      contentWarning: colors.yellow700,
      contentPositive: colors.green700,
      contentDevelopment: colors.development,
      contentProduction: colors.purple500,
      contentDisabledDevelopment: colors.disabledDevelopment,

      backgroundTertiary: colors.primary50,
      backgroundQuaternary: colors.primary200,
      backgroundQuinary: colors.primary300,
      backgroundInverseTertiary: colors.primary700,
      backgroundInfo: colors.cyan50,
      backgroundNegative: colors.red50,
      backgroundWarning: colors.yellow50,
      backgroundPositive: colors.green50,
      backgroundSubtleBlue: colors.electricBlue50,
      backgroundProduction: "#C5ADFA36",

      border: colors.alpha300,
      borderFocus: colors.electricBlue500,
      borderSelected: colors.electricBlue400,
      borderOpaque: colors.alpha300,
      borderAlt: colors.primary200,
      borderInfo: colors.cyan500,
      borderNegative: colors.red500,
      borderError: colors.red500,
      borderWarning: colors.yellow600,
      borderPositive: colors.green200,

      buttonPrimaryFill: colors.primary700,
      buttonPrimaryHover: colors.primary600,
      buttonSecondaryFill: colors.neutralMin,
      buttonSecondaryHover: colors.primary50,
      buttonTertiaryHover: colors.alpha0,

      inputFill: "rgba(0, 0, 0, 0.01)",
      inputFillActive: colors.neutralMin,
      inputBorder: colors.primary300,
      inputBorderError: colors.red500,

      linkText: colors.primary700,
      linkVisited: colors.primary700,
      linkHover: colors.primary600,

      contentLinkText: colors.blue500,
      contentLinkHover: colors.blue600,
      contentLinkActive: colors.blue700,

      menuFontDefault: colors.neutralMax,

      tickBorderError: colors.red500,
      tickFillErrorSelected: colors.red500,
      tickFillErrorSelectedHover: colors.red600,
      tickFillErrorSelectedHoverActive: colors.red700,

      backgroundEnvironmentSettingsCallout: colors.backgroundEnvironmentSettingsCalloutDevelopment,
      contentEnvironmentSettingsCallout: colors.contentEnvironmentSettingsCalloutDevelopment,
    },
    borders: {
      buttonBorderRadius: "6px",
      inputBorderRadius: "6px",
      popoverBorderRadius: "6px",
      surfaceBorderRadius: "8px",
      tableBorderRadius: "8px",
    },
  };

  if (type == SemanticType.Production) {
    const productionOverrides = {
      colors: {
        themedAlpha50: colors.purpleAlpha50,
        themedAlpha100: colors.purpleAlpha100,
        themedAlpha200: colors.purpleAlpha200,
        themedAlpha300: colors.purpleAlpha300,
        themedAlpha400: colors.purpleAlpha400,
        themedAlpha500: colors.purpleAlpha500,
        themedAlpha600: colors.purpleAlpha600,
        themedAlpha700: colors.purpleAlpha700,

        themedPrimary50: colors.purple50,
        themedPrimary100: colors.purple100,
        themedPrimary200: colors.purple200,
        themedPrimary300: colors.purple300,
        themedPrimary400: colors.purple400,
        themedPrimary500: colors.purple500,
        themedPrimary600: colors.purple600,
        themedPrimary700: colors.purple700,

        contentSecondaryOriginal: colors.purple600,
        contentInfo: colors.purple600,

        backgroundTertiary: colors.purple50,
        backgroundQuaternary: colors.purple100,
        backgroundQuinary: colors.purple300,
        backgroundInverseTertiary: colors.purple700,
        backgroundInfo: colors.purple50,

        borderFocus: colors.purple500,
        borderSelected: colors.purple400,
        borderOpaque: colors.purple200,
        borderAlt: colors.purple200,
        borderInfo: colors.purple200,

        tableStripedBackground: colors.purple50,

        backgroundEnvironmentSettingsCallout: colors.backgroundEnvironmentSettingsCalloutProduction,
        contentEnvironmentSettingsCallout: colors.contentEnvironmentSettingsCalloutProduction,
      },
      borders: {
        // NOTE: These colours are opaque, while the default border colours are transparent
        border100: { borderColor: colors.purple50 },
        border200: { borderColor: colors.purple100 },
        border300: { borderColor: colors.purple200 },
        border400: { borderColor: colors.purple300 },
        border500: { borderColor: colors.purple400 },
        border600: { borderColor: colors.purple500 },
      },
    };

    return merge({}, developmentOverrides, productionOverrides);
  } else {
    return developmentOverrides;
  }
};

// PRIMITIVE OVERRIDES ////////////////////////////////////////////////////////
export const primitivesLight: Partial<ThemePrimitives> = {
  ...getPrimitiveColors(gadgetColorsLight),
};

export const primitivesDark: Partial<ThemePrimitives> = {
  ...getPrimitiveColors(gadgetColorsDark),
};

export type GadgetStyletronTheme = Theme & SemanticOverrides & EditOverrides;

// COLOR PALETTE OVERRIDES ////////////////////////////////////////////////////
export const defaultSemanticOverrides = getSemanticOverrides(SemanticType.Development, gadgetColorsLight);
export type SemanticOverrides = ReturnType<typeof getSemanticOverrides>;

export const EditLightTheme: GadgetStyletronTheme = createTheme(primitivesLight, merge({}, defaultSemanticOverrides, editOverrides));

export const EditLightProductionTheme: GadgetStyletronTheme = createTheme(
  primitivesLight,
  merge({}, getSemanticOverrides(SemanticType.Production, gadgetColorsLight), editOverrides)
);

export const EditDarkTheme: GadgetStyletronTheme = createDarkTheme(
  primitivesDark,
  merge({}, getSemanticOverrides(SemanticType.Development, gadgetColorsDark), editOverrides)
);

export const EditDarkProductionTheme: GadgetStyletronTheme = createDarkTheme(
  primitivesDark,
  merge({}, getSemanticOverrides(SemanticType.Production, gadgetColorsDark), editOverrides)
);

// for debugging, its nice to be able to just see the theme values easily
if (process.env.NODE_ENV != "production" && typeof window != "undefined") {
  (window as any).EditLightTheme = EditLightTheme;
  (window as any).EditLightProductionTheme = EditLightProductionTheme;
  (window as any).EditDarkTheme = EditDarkTheme;
  (window as any).EditDarkProductionTheme = EditDarkProductionTheme;
}

export const EditLayout = {
  smallSubNavigationWidth: "368px",
  mediumSubNavigationWidth: "392px",
  minimumEditPaneWidth: "432px",
  maximumEditPaneWidth: "816px",
  connectionsIndexWidth: "1048px",
  connectionsModalWidth: "900px",
  appSettingIndexWidth: "1150px",
  resizablePaneStartWidth: 526,
};
