import { StyledSvg } from "baseui/icon";
import React from "react";

export const CloseIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.14478 3.17316C2.18007 3.34135 2.26456 3.49524 2.3875 3.61529L6.77382 8.00162L2.38794 12.3875C2.3055 12.4671 2.23964 12.5622 2.19418 12.6673C2.14843 12.7732 2.12426 12.8871 2.12309 13.0024C2.12192 13.1177 2.14376 13.2321 2.18735 13.3388C2.23093 13.4456 2.29537 13.5426 2.37691 13.6241C2.45844 13.7056 2.55542 13.7701 2.66217 13.8136C2.76893 13.8572 2.8833 13.8791 2.9986 13.8779C3.1139 13.8767 3.22781 13.8526 3.33365 13.8068C3.43883 13.7614 3.53395 13.6955 3.6135 13.613L7.99937 9.22717L12.3853 13.613C12.4648 13.6955 12.5599 13.7614 12.6651 13.8068C12.7709 13.8526 12.8848 13.8767 13.0001 13.8779C13.1155 13.8791 13.2298 13.8572 13.3366 13.8136C13.4433 13.7701 13.5403 13.7056 13.6218 13.6241C13.7034 13.5426 13.7678 13.4456 13.8114 13.3388C13.855 13.2321 13.8768 13.1177 13.8757 13.0024C13.8745 12.8871 13.8503 12.7732 13.8046 12.6673C13.7591 12.5622 13.6932 12.4671 13.6108 12.3875L9.22492 8.00162L13.6108 3.61576C13.736 3.49505 13.822 3.33945 13.8576 3.16917C13.8934 2.99815 13.8767 2.82032 13.8096 2.65899C13.7425 2.49766 13.6282 2.36037 13.4818 2.26512C13.3353 2.16987 13.1635 2.12111 12.9888 2.12524C12.7605 2.13063 12.5436 2.22588 12.3852 2.39025L7.99937 6.77607L3.61306 2.38975C3.5324 2.30713 3.43605 2.24144 3.32965 2.19657C3.2228 2.1515 3.10793 2.12834 2.99196 2.12844C2.81963 2.12866 2.65127 2.18024 2.5084 2.2766C2.36553 2.37296 2.25462 2.50972 2.18985 2.66942C2.12508 2.82912 2.10939 3.0045 2.14478 3.17316Z"
    />
  </StyledSvg>
);
