import { useStyletron } from "baseui";
import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";
import React from "react";
import type { HotKeysOverrideProps, HotKeysProps } from "react-hotkeys-ce";
import { HotKeys, IgnoreKeys } from "react-hotkeys-ce";
import { tryWithUserFeedback } from "./feedback";

export const KeyNormalizedControl = observer(
  (props: {
    children: ReactNode;
    engagementBoundary?: boolean;
    hasHotKeys?: boolean;
    mode?: "text" | "textarea" | "select" | "simple" | "form";
    onFinish?: () => void;
    ignoreKeyProps?: HotKeysOverrideProps;
  }) => {
    const [, $theme] = useStyletron();

    const ignoreKeyProps: HotKeysOverrideProps = { ...(props.ignoreKeyProps ?? {}) };
    const hotKeyProps: HotKeysProps = {
      handlers: {},
      keyMap: {},
    };

    const baseExceptions = ["escape", "meta", "ctrl", "shift", "p", "k"];

    if (props.mode == "form") {
      ignoreKeyProps.only = ["up", "down", "left", "right", "enter", "space", "Delete", "backspace"];
    } else if (props.mode == "simple") {
      ignoreKeyProps.only = ["enter", "space"];
    } else if (props.mode == "select") {
      ignoreKeyProps.except = baseExceptions;
    } else if (props.mode == "textarea") {
      ignoreKeyProps.except = baseExceptions;
    } else if (props.engagementBoundary) {
      ignoreKeyProps.except = [...baseExceptions, "up", "down"];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      hotKeyProps.keyMap!["FINISHED_EDITING"] = ["enter"];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      hotKeyProps.handlers!["FINISHED_EDITING"] = (event) => {
        event?.preventDefault();
        tryWithUserFeedback(() => props.onFinish?.());
      };
    } else {
      ignoreKeyProps.except = [...baseExceptions, "up", "down", "enter"];
    }

    const innerContent = <IgnoreKeys {...ignoreKeyProps}>{props.children}</IgnoreKeys>;

    return props.hasHotKeys ? (
      <HotKeys {...hotKeyProps} style={{ minHeight: "32px" }}>
        {innerContent}
      </HotKeys>
    ) : (
      innerContent
    );
  }
);
