import { useEffect, useRef } from "react";

// this approach from https://github.com/molefrog/wouter/blob/master/use-location.js
const eventPopstate = "popstate";
const eventPushState = "pushState";
const eventReplaceState = "replaceState";

const historyEvents = [eventPopstate, eventPushState, eventReplaceState];

export type Area = "edit" | "docs" | "auth";

if (typeof history !== "undefined") {
  for (const type of [eventPushState, eventReplaceState]) {
    const original = (history as any)[type];

    (history as any)[type] = function (...args: any) {
      const result = original.apply(this, args);
      const event = new Event(type);
      (event as any).arguments = args;

      dispatchEvent(event);
      return result;
    };
  }
}

const getRudderAnalytics = () => {
  if ("rudderanalytics" in window) {
    return (window as any).rudderanalytics;
  }
};

export const usePageTracking = (getProperties: (() => Properties) | undefined = undefined) => {
  const currentPath = useRef<string | undefined>(undefined);

  useEffect(() => {
    const onHistoryEvent = () => {
      const newPath = location.pathname;
      if (!currentPath.current) {
        currentPath.current = newPath;
        return;
      }

      if (currentPath.current !== newPath) {
        currentPath.current = newPath;
        const properties = getProperties ? getProperties() : {};
        trackPage(properties);
      }
    };

    historyEvents.forEach((e) => addEventListener(e, onHistoryEvent));
    onHistoryEvent();

    return () => historyEvents.forEach((e) => removeEventListener(e, onHistoryEvent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export interface Properties {
  [index: string]: string | number | boolean | undefined | Properties | (string | number | boolean | Properties)[];
}

export const trackPage = (properties: Properties = {}) => {
  const analytics = getRudderAnalytics();

  analytics?.page(undefined, undefined, properties);
};

export const identifyUserId = (userId: string | number | bigint, traits?: Properties) => {
  const analytics = getRudderAnalytics();

  analytics?.identify(`${process.env.NODE_ENV}-user-${String(userId)}`, traits);
};

export const trackEvent = (name: string, properties: Properties, area?: Area) => {
  const analytics = getRudderAnalytics();

  if (!analytics) return;

  let defaultProperties: Properties = {};

  if (area === "edit" && (window as any)?.GadgetEditConfig) {
    const { applicationId, environmentId } = (window as any).GadgetEditConfig;

    defaultProperties = { application_id: applicationId, environment_id: environmentId };
  }

  analytics.track(name, { ...defaultProperties, ...properties });
};
