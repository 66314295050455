import { StyledSvg } from "baseui/icon";
import type { ComponentProps } from "react";
import React from "react";

export const CheckmarkIcon = (props: ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0 17 16" {...props}>
      <path
        d="M15.4528 4.28832C15.5652 4.17849 15.642 4.03738 15.6731 3.8833C15.7044 3.72859 15.6883 3.56802 15.6268 3.42265C15.5652 3.27728 15.4612 3.15388 15.3284 3.06864C15.1955 2.98339 15.04 2.94027 14.8822 2.94492C14.6794 2.95096 14.4867 3.03549 14.345 3.18066L6.36668 11.159L3.18883 7.98112C3.11704 7.90688 3.03128 7.84756 2.93647 7.80658C2.84081 7.76524 2.73785 7.74339 2.63364 7.74233C2.52943 7.74127 2.42605 7.76102 2.32957 7.80041C2.23308 7.8398 2.14543 7.89805 2.07173 7.97174C1.99804 8.04543 1.93979 8.13309 1.9004 8.22958C1.86101 8.32606 1.84127 8.42944 1.84233 8.53365C1.84339 8.63786 1.86523 8.74081 1.90658 8.83648C1.94755 8.93129 2.00687 9.01705 2.08111 9.08884L5.81282 12.8205C5.95972 12.9674 6.15897 13.0499 6.36668 13.0499C6.57439 13.0499 6.7736 12.9674 6.9205 12.8206L15.4528 4.28832Z"
        fill="currentColor"
      />
    </StyledSvg>
  );
};
