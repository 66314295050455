import { useStyletron } from "baseui";
import { SIZE, StyledSpinnerNext } from "baseui/spinner";
import React, { useEffect, useState } from "react";

/** Renders a spinner that centers itself horizontally and vertically in the parent container */
export const CenteredSpin = (props?: { children?: React.ReactNode }) => {
  const [css, $theme] = useStyletron();

  return (
    <div className={css({ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" })}>
      <div
        className={css({
          paddingLeft: $theme.sizing.scale600,
          paddingRight: $theme.sizing.scale600,
          paddingTop: $theme.sizing.scale600,
          paddingBottom: $theme.sizing.scale600,
          display: "flex",
          flexDirection: "column",
          gap: $theme.sizing.scale600,
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <StyledSpinnerNext $size={SIZE.large} />
        {props?.children}
      </div>
    </div>
  );
};

export const DelayedCenteredSpin = (props: { delay?: number }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const delay = props.delay ?? 0;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, delay ?? 0);

    return () => clearTimeout(timer);
  }, [delay]);

  return showSpinner ? <CenteredSpin /> : null;
};
