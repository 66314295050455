import createFlags from "flag";
import { merge } from "lodash";
import { observable } from "mobx";
import type { GadgetFlags } from "state-trees/src/GadgetFlags";
import { ClientSideDefaultFlags } from "state-trees/src/GadgetFlags";
export { Flag, FlagsProvider, useFlag, useFlags };

declare global {
  interface Window {
    GadgetFlags: GadgetFlags;
  }
}

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags<GadgetFlags>();
export const CurrentFlags: GadgetFlags = observable(typeof window === "undefined" ? ClientSideDefaultFlags : window.GadgetFlags);

if (typeof window !== "undefined") {
  /** Utility for setting flag values in tests */
  (window as any).mockFlags = (newFlags: Partial<GadgetFlags>) => {
    merge(CurrentFlags, newFlags);
  };
}
