import { styled } from "baseui";
import type { JustifyContent } from "baseui/block";

export const Row = styled<{ $gap?: string; $align?: JustifyContent }, "div">("div", (props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: props.$align,
  alignItems: "center",
  gap: props.$gap,
}));

export const Spacer = styled("div", {
  flexGrow: 1,
});
