import type { SelectProps } from "baseui/select";
import { createContext, useContext } from "react";
import type { ButtonProps } from "./button/Button";
import type { DeleteButtonProps } from "./button/DeleteButton";
import type { InputProps } from "./input/Input";
import type { TextareaProps } from "./input/Textarea";

export type WidgetsContextValue = {
  disableInputs?: <T extends InputProps | SelectProps | TextareaProps>(inputProps: T) => boolean | undefined;
  readonlyInputs?: <T extends InputProps | SelectProps | TextareaProps>(inputProps: T) => boolean | undefined;
  disableButtons?: <T extends ButtonProps | DeleteButtonProps>(buttonProps: T) => boolean | undefined;
};

export const WidgetsContext = createContext<WidgetsContextValue>({});

export const useWidgets = () => {
  const context = useContext(WidgetsContext);

  return context ?? {};
};
