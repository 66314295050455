import { UserInitiatedActionSemanticError } from "@gadgetinc/errors";
import { toaster } from "baseui/toast";

const messageWithoutCode = (message: string) => {
  return message.substring(message.indexOf(":") + 1);
};

export const tryWithUserFeedback = (action: () => void) => {
  try {
    action();
  } catch (error) {
    if (error instanceof UserInitiatedActionSemanticError) {
      toaster.negative(messageWithoutCode(error.message), { autoHideDuration: 3000 });
    } else {
      throw error;
    }
  }
};
