import type { BorderBottomStyleProperty, BorderLeftStyleProperty, BorderRightStyleProperty, BorderTopStyleProperty } from "@rtsao/csstype";
import type { Border, Font, Typography } from "baseui/theme";
import { isFunction } from "lodash";
import type { DependencyList } from "react";
import { useCallback } from "react";
import ReactDOM from "react-dom";
import type { StyleObject } from "styletron-standard";
import type { DeepPartial } from "ts-essentials";

export type PrimitiveFont = Partial<Font> & {
  textDecoration?: string;
  textAlign?: string;
  primaryFont?: Partial<Font>;
  monoFont?: Partial<Font>;
};

export interface PrimitiveTypography {
  title: PrimitiveFont;
  navTitle: PrimitiveFont;
  heading: PrimitiveFont;
  subHeading: PrimitiveFont;
  body: PrimitiveFont;
  caption: PrimitiveFont;
  contentLink: PrimitiveFont;
}

// Derived from https://github.com/gadget-inc/baseweb/blob/build-1b4286d28/themes/shared/typography.js#L155-L172
export const PrimitiveTypographyMap: Record<string, keyof NonNullable<PrimitiveTypography>> = {
  ParagraphXSmall: "caption",
  ParagraphSmall: "body",
  ParagraphMedium: "body",
  ParagraphLarge: "body",
  LabelXSmall: "caption",
  LabelSmall: "body",
  LabelMedium: "body",
  LabelLarge: "body",
  HeadingXSmall: "caption",
  HeadingSmall: "subHeading",
  HeadingMedium: "heading",
  HeadingLarge: "heading",
  HeadingXLarge: "heading",
  HeadingXXLarge: "title",
  DisplayXSmall: "caption",
  DisplaySmall: "body",
  DisplayMedium: "subHeading",
  DisplayLarge: "heading",
  font100: "caption",
  font150: "caption",
  font200: "body",
  font250: "body",
  font300: "body",
  font350: "body",
  font400: "subHeading",
  font450: "subHeading",
  font550: "heading",
  font650: "heading",
  font750: "title",
  font850: "title",
  font950: "title",
  font1050: "title",
  font1150: "title",
  font1250: "title",
  font1350: "title",
  font1450: "title",
};

export const DISCORD_INVITE_URL = "https://ggt.link/discord";

/**
 * BaseUI has a bunch of redundant typography styles that can be
 * derived from a smaller set of primitives. This helper generates
 * those redundant styles.
 */
export const generateTypography = (
  families: { primaryFamily: string; monoFamily: string },
  primitives: PrimitiveTypography
): DeepPartial<Typography> =>
  Object.fromEntries(
    Object.entries(PrimitiveTypographyMap).flatMap(([name, primitiveName]) => {
      const { primaryFont: primitivePrimaryFont = {}, monoFont: primitiveMonoFont = {}, ...primitiveBaseFont } = primitives[primitiveName];

      const primaryFont = {
        fontFamily: families.primaryFamily,
        ...primitiveBaseFont,
        ...primitivePrimaryFont,
      };

      const monoFont = {
        fontFamily: families.monoFamily,
        ...primitiveBaseFont,
        ...primitiveMonoFont,
      };

      return [
        [primitiveName, primaryFont],
        [name, primaryFont],
        [`Mono${name}`, monoFont],
      ];
    })
  );

const nullBorder: Border = {
  borderColor: "transparent",
  borderStyle: "solid",
  borderWidth: "0px",
};

export const borderRadiiTop = (borderRadius: string): StyleObject => {
  return {
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
  };
};

export const borderRadiiRight = (borderRadius: string): StyleObject => {
  return {
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  };
};

/**
 *
 * @param border
 * @returns { borderRightWidth, borderRightStyle, borderRightColor }
 */
export const expandBorderRight = (border: Border | "none"): Record<string, string> => {
  if (border === "none") return expandBorderRight(nullBorder);
  return {
    borderRightWidth: border.borderWidth,
    borderRightStyle: border.borderStyle as BorderRightStyleProperty,
    borderRightColor: border.borderColor,
  };
};

/**
 *
 * @param border
 * @returns { borderLeftWidth, borderLeftStyle, borderLeftColor }
 */
export const expandBorderLeft = (border: Border | "none"): Record<string, string> => {
  if (border === "none") return expandBorderLeft(nullBorder);
  return {
    borderLeftWidth: border.borderWidth,
    borderLeftStyle: border.borderStyle as BorderLeftStyleProperty,
    borderLeftColor: border.borderColor,
  };
};

/**
 *
 * @param border
 * @returns borderTopWidth, borderTopStyle, borderTopColor
 */
export const expandBorderTop = (border: Border | "none"): Record<string, string> => {
  if (border === "none") return expandBorderTop(nullBorder);
  return {
    borderTopWidth: border.borderWidth,
    borderTopStyle: border.borderStyle as BorderTopStyleProperty,
    borderTopColor: border.borderColor,
  };
};

/**
 *
 * @param border
 * @returns { borderBottomWidth, borderBottomStyle, borderBottomColor }
 */
export const expandBorderBottom = (border: Border | "none"): Record<string, string> => {
  if (border === "none") return expandBorderBottom(nullBorder);
  return {
    borderBottomWidth: border.borderWidth,
    borderBottomStyle: border.borderStyle as BorderBottomStyleProperty,
    borderBottomColor: border.borderColor,
  };
};

export const borderRadiiBottom = (borderRadius: StyleObject["borderRadius"]): StyleObject => {
  return {
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  };
};

export const borderRadiiLeft = (borderRadius: StyleObject["borderRadius"]): StyleObject => {
  return {
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
  };
};

export const expandBorderRadii = (borderRadius: StyleObject["borderRadius"]): StyleObject => {
  return {
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  };
};

export const expandBorderColors = (borderColor: StyleObject["borderColor"]): StyleObject => {
  return {
    borderTopColor: borderColor,
    borderRightColor: borderColor,
    borderBottomColor: borderColor,
    borderLeftColor: borderColor,
  };
};

export const expandBorderStyles = (borderStyle: string | undefined): StyleObject => {
  return {
    borderTopStyle: borderStyle as StyleObject["borderTopStyle"],
    borderRightStyle: borderStyle as StyleObject["borderTopStyle"],
    borderBottomStyle: borderStyle as StyleObject["borderTopStyle"],
    borderLeftStyle: borderStyle as StyleObject["borderTopStyle"],
  };
};

export const expandBorderWidth = (borderWidth: StyleObject["borderWidth"]): StyleObject => {
  return {
    borderTopWidth: borderWidth,
    borderRightWidth: borderWidth,
    borderBottomWidth: borderWidth,
    borderLeftWidth: borderWidth,
  };
};

export const expandBaseWebBorder = (border: Border): StyleObject => {
  return {
    ...expandBorderWidth(border.borderWidth),
    ...expandBorderColors(border.borderColor),
    ...expandBorderStyles(border.borderStyle),
  };
};

/**
 *
 * @param margin
 * @returns { marginTop, marginLeft, marginRight, marginBottom }
 */
export const expandMargin = (margin: string | number) => ({
  marginTop: margin,
  marginRight: margin,
  marginBottom: margin,
  marginLeft: margin,
});

/**
 *
 * @param padding
 * @returns { paddingTop, paddingLeft, paddingRight, paddingBottom }
 */
export const expandPadding = (padding: string | number) => ({
  paddingTop: padding,
  paddingRight: padding,
  paddingBottom: padding,
  paddingLeft: padding,
});

/**
 * @param margin
 * @param [margin2=margin]
 * @returns { marginLeft, marginRight }
 */
export const marginHorizontal = (margin: string | number, margin2 = margin) => ({
  marginLeft: margin,
  marginRight: margin2,
});

/**
 *
 * @param margin
 * @param [margin2=margin]
 * @returns { marginTop, marginBottom }
 */
export const marginVertical = (margin: string | number, margin2 = margin) => ({
  marginTop: margin,
  marginBottom: margin2,
});

/**
 * @param padding
 * @param [padding2=padding]
 * @returns { paddingLeft, paddingRight }
 */
export const paddingHorizontal = (padding: string | number, padding2 = padding) => ({
  paddingLeft: padding,
  paddingRight: padding2,
});

/**
 *
 * @param padding
 * @param [padding2=padding]
 * @returns { paddingTop, paddingBottom }
 */
export const paddingVertical = (padding: string | number, padding2 = padding) => ({
  paddingTop: padding,
  paddingBottom: padding2,
});

/**
 * Use when you know padding sizes you want to set for all 4 sides of an element
 *
 * @param padding 1-4 size arguments (same order as CSS shorthand)
 * @returns { paddingTop, paddingLeft, paddingRight, paddingBottom }
 */
export const paddingUtil = (...padding: Array<string | number>) => {
  const [paddingArg1 = 0, paddingarg2, paddingArg3, paddingArg4] = padding;
  const paddingTop = paddingArg1;
  let paddingRight = paddingArg1,
    paddingBottom = paddingArg1,
    paddingLeft = paddingArg1;

  if (typeof paddingarg2 !== "undefined") {
    paddingRight = paddingarg2;
    paddingLeft = paddingarg2;
    if (typeof paddingArg3 !== "undefined") {
      paddingBottom = paddingArg3;
      if (typeof paddingArg4 !== "undefined") {
        paddingLeft = paddingArg4;
      }
    }
  }

  return {
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };
};

/**
 * Use when you know the margin sizes you want to set for all 4 sides of an element
 *
 * @param margin 1-4 size arguments (same order as CSS shorthand)
 * @returns { marginTop, marginLeft, marginRight, marginBottom }
 */
export const marginUtil = (...margin: Array<string | number>) => {
  const [marginArg1 = 0, marginarg2, marginArg3, marginArg4] = margin;
  const marginTop = marginArg1;
  let marginRight = marginArg1,
    marginBottom = marginArg1,
    marginLeft = marginArg1;

  if (typeof marginarg2 !== "undefined") {
    marginRight = marginarg2;
    marginLeft = marginarg2;
    if (typeof marginArg3 !== "undefined") {
      marginBottom = marginArg3;
      if (typeof marginArg4 !== "undefined") {
        marginLeft = marginArg4;
      }
    }
  }

  return {
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
  };
};

/** CSS inset box shadows don't go on top of borders, they go next to them. But, we want to blend the box shadow with the border for the nice lighting effect on buttons. So, we use a box shadow for the border, and then apply our nice lighting box shadows on top of that one so they blend nicely. */
export const borderShadow = (borderColor: string, borderWidth: number, boxShadow: string): string =>
  `${boxShadow}, 0 0 0 ${borderWidth}px ${borderColor} inset`;

/** Allows the use of multiple refs with one component */
export const multiref = <T>(...refs: React.Ref<T>[]) => {
  return (value: T) => {
    for (const ref of refs) {
      if (ref) {
        if (isFunction(ref)) {
          ref(value);
        } else {
          (ref as any).current = value;
        }
      }
    }
  };
};

export function assert<T>(value: T | undefined | null, message?: string): T {
  if (!value) {
    throw new Error("assertion error" + (message ? `: ${message}` : ""));
  }
  return value;
}

// Workaround for https://github.com/facebook/react/issues/19385
// Necessary for event handlers that change focus and change react component state at the same time.
export const useFlushSyncCallback = <T extends any[]>(handler: ((...args: T) => void) | undefined, deps: DependencyList) => {
  return useCallback((...args: T) => {
    (ReactDOM as any).flushSync(() => {
      if (handler) handler(...args);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
