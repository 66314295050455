import { useEffect } from "react";

/**
 * Emit an event the first time we're rendered for anyone listening
 * Used by Cypress to wait for the react app to hydrate before running tests
 */

export const useEmitReactReady = () => {
  useEffect(() => {
    if (typeof window != "undefined") {
      // fire an event on the window when the layout mounts for cypress to know the app has booted
      window.dispatchEvent(new Event("gadget:react:ready"));
      (window as any).gadgetReactReady = true;
    }
  }, []);
};
