import { mergeOverrides, useStyletron } from "baseui";
import { DeleteAlt } from "baseui/icon";
import { expandBorderWidth, expandPadding, paddingHorizontal, paddingUtil } from "../../utils";
// eslint-disable-next-line no-restricted-imports
import type { InputOverrides as BaseUIInputOverrides, InputProps as BaseUIInputProps } from "baseui/input";
// eslint-disable-next-line no-restricted-imports
import { Input as BaseUIInput, SIZE as InputSize } from "baseui/input";
import type { Overrides } from "baseui/overrides";
import { Popover } from "baseui/popover";
import type { RefObject } from "react";
import React, { forwardRef } from "react";
import { RichString } from "state-trees/src/RichString";
import type { StyleObject } from "styletron-react";
import { useWidgets } from "../contexts";

// eslint-disable-next-line no-restricted-imports
export { SIZE as InputSize } from "baseui/input";

export const InputErrorWrapper = (props: {
  children: React.ReactNode;
  error?: string;
  $style?: StyleObject;
  $containerStyle?: StyleObject;
  parentRef?: RefObject<HTMLElement>;
}) => {
  const [css, $theme] = useStyletron();

  const error = RichString.parseToString(props.error ?? "");

  return (
    <Popover
      isOpen={error !== "" && error !== undefined}
      content={
        <div
          className={css({
            whiteSpace: "normal",
            backgroundColor: $theme.colors.red400,
            ...$theme.typography.caption,
            color: $theme.colors.backgroundPrimary,
            ...paddingUtil($theme.sizing.scale100, $theme.sizing.scale300),
            borderRadius: $theme.borders.radius200,
            minWidth: `${props.parentRef?.current?.offsetWidth ?? 163}px`,
            maxWidth: `${props.parentRef?.current?.offsetWidth ?? 300}px`,
            wordWrap: "break-word",
            ...props.$style,
          })}
        >
          {error}
        </div>
      }
      placement="bottomLeft"
      triggerType="click"
    >
      <div className={css({ flexGrow: 1, zIndex: 2, height: "100%", ...props.$containerStyle })}>{props.children}</div>
    </Popover>
  );
};

export type InputProps = BaseUIInputProps & {
  dataTestId?: string;
  readonly?: boolean;
  suppress1Password?: boolean;
};

export type InputOverrides = BaseUIInputOverrides;
/**
 * Text input component
 *
 * Wraps BaseUI to add in our nice styling and behaviour
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { dataTestId, suppress1Password, ...inputProps } = props;
  const [_css, $theme] = useStyletron();
  const { disableInputs, readonlyInputs } = useWidgets();

  const size = props.size ?? InputSize.default;
  const disabled = props.disabled ?? disableInputs?.(props);
  const readonly = props.readonly ?? readonlyInputs?.(props);

  const inputSizes = {
    [InputSize.mini]: {
      paddingTop: $theme.sizing.scale100,
      paddingBottom: $theme.sizing.scale100,
      paddingLeft: props.startEnhancer ? 0 : $theme.sizing.scale300,
      paddingRight: $theme.sizing.scale300,
    },
    [InputSize.compact]: {
      paddingTop: $theme.sizing.scale100,
      paddingBottom: $theme.sizing.scale100,
      paddingLeft: props.startEnhancer ? 0 : $theme.sizing.scale300,
      paddingRight: $theme.sizing.scale300,
    },
    [InputSize.default]: {
      paddingTop: $theme.sizing.scale200,
      paddingBottom: $theme.sizing.scale200,
      paddingLeft: props.startEnhancer ? 0 : $theme.sizing.scale300,
      paddingRight: $theme.sizing.scale300,
    },
    [InputSize.large]: {
      paddingTop: $theme.sizing.scale300,
      paddingBottom: $theme.sizing.scale300,
      paddingLeft: props.startEnhancer ? 0 : $theme.sizing.scale550,
      paddingRight: $theme.sizing.scale550,
      ...$theme.typography.body,
    },
  }[size];

  return (
    <BaseUIInput
      inputRef={ref}
      {...inputProps}
      disabled={disabled || readonly}
      overrides={mergeOverrides(
        {
          Root: {
            style: ({ $theme, $isFocused, $disabled }) => ({
              ...expandBorderWidth("1px"),
              ...expandPadding(0),
              backgroundColor:
                readonly || $disabled ? $theme.colors.alpha50 : $isFocused ? $theme.colors.backgroundPrimary : $theme.colors.inputAlpha,
              boxShadow: $disabled
                ? undefined
                : $isFocused
                ? `inset 0 0 0 1px ${$theme.colors.borderFocus}`
                : $theme.lighting.shadowInputInset,
              [":hover"]: {
                borderColor: $disabled ? undefined : $isFocused ? $theme.colors.borderFocus : $theme.colors.primary500,
              },
            }),
          },
          Input: {
            props: {
              "data-testid": dataTestId,
              "data-1p-ignore": suppress1Password,
            },
            style: ({ $theme, $disabled }) => ({
              ...inputSizes,
              ...(readonly
                ? {
                    color: $theme.colors.contentPrimary,
                    "-webkit-text-fill-color": $theme.colors.alpha600,
                    cursor: "auto",
                  }
                : $disabled
                ? { color: $theme.colors.alpha400 }
                : { color: $theme.colors.contentPrimary }),
              "::placeholder": {
                color: $theme.colors.alpha400,
                "-webkit-text-fill-color": $theme.colors.alpha400,
              },
            }),
          },
          InputContainer: {
            style: {
              backgroundColor: "transparent",
            },
          },
          StartEnhancer: {
            style: {
              backgroundColor: "transparent",
              ...paddingHorizontal($theme.sizing.scale200),
            },
          },
          EndEnhancer: {
            style: {
              backgroundColor: "transparent",
              ...paddingHorizontal($theme.sizing.scale200),
            },
          },
          ClearIcon: <DeleteAlt size={16} />,
        },
        // TypeScript can't determine that InputOverrides interface is compatible with Overrides<any>
        // See https://github.com/microsoft/TypeScript/issues/15300
        props.overrides as Overrides<any> | undefined
      )}
    />
  );
});
