import { toaster } from "baseui/toast";
import type { IPromiseBasedObservable } from "mobx-utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { FlashMessage } from "state-trees/src/utils";
import type { StyleObject } from "styletron-react";
import type { CombinedError } from "urql";
import { reportFrontendErrorForInvestigation } from "./reportFrontendErrorForInvestigation";
export { assert, multiref } from "@gadgetinc/widgets";

export interface HubspotWidget {
  status: () => { loaded: boolean };
  refresh: (opts?: { openToNewThread?: boolean }) => void;
  open: () => void;
  load: (options?: { widgetOpen: boolean }) => void;
}

declare global {
  interface Window {
    GadgetMode: "Edit" | "Render" | "Server";
    HubSpotConversations?: {
      widget: HubspotWidget;
    };
    hsConversationsSettings: {
      loadImmediately: boolean;
      identificationEmail?: string;
      identificationToken?: string;
    };
  }
}

export const openWidgetOnLoad = (widget: HubspotWidget) => {
  setTimeout(() => {
    const status = widget.status();

    if (status.loaded) {
      widget.open();
    } else {
      openWidgetOnLoad(widget);
    }
  }, 500);
};

export const timeout = <T,>(timeoutMs: number, promise: Promise<T> | IPromiseBasedObservable<T>, failureMessage?: string) => {
  let timeoutHandle: any = null;
  const timeoutPromise = new Promise<never>((resolve, reject) => {
    timeoutHandle = setTimeout(() => reject(new Error(failureMessage)), timeoutMs);
  });

  return Promise.race([promise, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};

export const getChildrenText = (children: React.ReactNode | React.ReactNode[]) => {
  let label = "";
  React.Children.forEach(children, (child) => {
    if (!child) return;
    if (typeof child === "string") {
      label += child;
    }
    if (typeof child === "object" && "props" in child) {
      if ((typeof child.type === "function" || typeof child.type === "object") && "textContent" in child.type) {
        label += (child.type as any).textContent;
      }

      if (child.props.children) {
        label += getChildrenText(child.props.children);
      }
    }
  });
  return label;
};

export const genericNegativeToaster = (message = "Something went wrong. Please try again in a few seconds.") => {
  toaster.negative(message, { autoHideDuration: 3000 });
};

/**
 * If you have an event handler that needs to run async business logic, you should wrap it in this to make it a synchronous handler that still runs and catches async errors.
 * Runs the `handler` function right away, expecting it to return a promise, and logs any promise rejections.
 * This is used for the same thing as (and serves serves the same purpose as) `safelyRunAsyncOutOfBand` in the Gadget server side code
 **/
export const safelyRunAsyncEventHandler = (handler: () => Promise<void>) => {
  handler().catch((error) => {
    reportFrontendErrorForInvestigation(error);
  });
};

/**
 * Function wrapper for building a sync event handler out of an async event handler using `safelyRunAsyncEventHandler`
 * Returns a new handler function.
 **/
export const asyncSafeEventHandler = <T extends any[]>(handler: (...args: T) => Promise<void>): ((...args: T) => void) => {
  return (...args) =>
    safelyRunAsyncEventHandler(async () => {
      return await handler(...args);
    });
};

/** Spreadable styles to delay hiding an element—e.g., to make it NOT focusable, AFTER a transition/animation */
export const delayedHide = (isHidden: boolean, delay: number | string = "0.5s"): StyleObject => ({
  animationName: isHidden ? "delayedHide" : "none",
  animationDuration: typeof delay === "number" ? `${delay}ms` : delay,
  animationTimingFunction: "linear",
  animationFillMode: "both",
});

export const flat = (object: Record<string, any>) => {
  const result: Record<string, any> = {};
  function step(object: Record<string, any>, previous?: string) {
    Object.keys(object).forEach(function (key) {
      const value = object[key];
      const type = Object.prototype.toString.call(value);
      const isobject = type === "[object Object]" || type === "[object Array]";

      const newKey = previous ? previous + "." + key : key;

      if (isobject && Object.keys(value).length) {
        return step(value, newKey);
      }

      result[newKey] = value;
    });
  }
  step(object);
  return result;
};

export const isPaymentRequiredError = (error: Error | CombinedError) => {
  return "graphQLErrors" in error && error.graphQLErrors.some((error) => error.message.includes("GGT_PAYMENT_REQUIRED"));
};

export const useDisplayFlashMessage = (flashMessage?: FlashMessage | null) => {
  useEffect(() => {
    let flashKey: React.Key;

    const toastOptions = { autoHideDuration: 4000 };
    if (!flashMessage) return;

    const { type, message } = flashMessage;

    switch (type) {
      case "error":
        flashKey = toaster.negative(message, toastOptions);
        break;
      case "warning":
        flashKey = toaster.warning(message, toastOptions);
        break;
      case "info":
        flashKey = toaster.info(message, toastOptions);
        break;
      case "success":
        flashKey = toaster.positive(message, toastOptions);
        break;
    }

    return () => toaster.update(flashKey, { autoHideDuration: 1 });
  }, [flashMessage]);
};

export const useIdentifiedSupportConversation = (
  loadUserIdentification: () => Promise<{ email?: string; token?: string } | undefined>,
  opts: { loadImmediately?: boolean; currentUser?: { createdDate: string } } = {}
) => {
  const [loaded, setLoaded] = useState(false);
  const [identificationStarted, setIdentificationStarted] = useState(false);
  const identifyPromise = useRef<Promise<void> | null>(null);
  const mounted = useRef(false);

  const loadWidget = useCallback(async () => {
    if (mounted.current) {
      await identifyPromise.current;
      window.HubSpotConversations?.widget.load();
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (opts.loadImmediately && !loaded && identificationStarted) {
      safelyRunAsyncEventHandler(loadWidget);
    }
  }, [loadWidget, loaded, opts.loadImmediately, identificationStarted]);

  useEffect(() => {
    mounted.current = true;

    if (typeof window != "undefined") {
      identifyPromise.current = loadUserIdentification()
        .then((result) => {
          if (result && result.email) {
            window.hsConversationsSettings.identificationEmail = result.email;
          }

          if (result && result.token) {
            window.hsConversationsSettings.identificationToken = result.token;
          }
        })
        .catch(reportFrontendErrorForInvestigation);

      setIdentificationStarted(true);
    }

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [loaded, loadWidget] as const;
};

/** When we want a component to not render and prevent any of it's children from rendering, we can suspend the component, and rely on something else re-rendering soon after to remove it from the tree altogether
 *
 * This is weird, but useful when we have react components that are currently mounted and referencing MST nodes that have been deleted. These react components may have captured references to the MST nodes, which once dead, will throw on any property access. So, instead of letting them re-render and potentially touch the dead nodes, we suspend at the root of the tree and unmount 'em all forcefully.
 */
export const suspendForever = () => {
  // eslint-disable-next-line @typescript-eslint/only-throw-error
  throw new Promise(() => {});
};
