import React from "react";
import Logomark from "../../assets/logomark.svg";
import Logotype from "../../assets/logotype.svg";

export const GadgetLogoType = (props: JSX.IntrinsicElements["img"]) => {
  return <img {...props} src={Logotype} />;
};

export const GadgetLogoMark = (props: JSX.IntrinsicElements["img"]) => {
  return <img {...props} src={Logomark} />;
};
