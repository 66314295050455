import { styled } from "baseui";
import type { Theme } from "baseui/theme";
import { merge } from "lodash";
import type { StyleObject } from "styletron-react";
import { expandBorderWidth, expandMargin, expandPadding } from "../../utils";

/**
 * A plain button component that doesn't apply any styling to the contents
 *
 * Useful for making clickable elements inside other things. Try to use this instead of a `div` or `Row` with an `onClick` :)
 */
export const PlainButton = styled<object & { $row?: boolean; $selected?: boolean; $style?: StyleObject }, "button", Theme>(
  "button",
  ({ $theme, $row, $selected, $style }) => {
    return merge(
      {
        fontFamily: "inherit",
        lineHeight: "inherit",
        fontWeight: "inherit",
        fontSize: "inherit",
        textDecoration: "underline",
        display: $row ? "flex" : "initial",
        alignItems: "center",
        ...expandPadding(0),
        ...expandMargin(0),
        ...expandBorderWidth("0px"),
        backgroundColor: $selected ? $theme.colors.primary200 : "unset",
        width: "min-content",
        whiteSpace: "nowrap",
        textUnderlinePosition: "under",
        color: "unset",
        ":hover": {
          backgroundColor: $selected ? $theme.colors.primary200 : "unset",
          color: $theme.colors.contentTertiary,
          cursor: "pointer",
        },
        ":active": {
          backgroundColor: $selected ? $theme.colors.primary200 : "unset",
        },
        ":focus-visible": {
          outline: `auto 1px ${$theme.colors.borderFocus}`,
        },
      },
      $style
    );
  }
);
