import type { SelectOverrides } from "baseui/select";
import { mapValues, values } from "lodash";
import React, { useMemo, useRef } from "react";
import type { StyleObject } from "styletron-react";

function maybeMerge<T>(a: T | undefined, b: T | undefined) {
  return a && b ? { ...a, ...b } : a || b;
}

export interface RequiredPropsShape {
  style?: StyleObject;
  overrides?: { [key: string]: Override<any> };
}

export type Override<P extends RequiredPropsShape> = {
  style?: StyleObject | ((props: P) => StyleObject);
  component?: React.ComponentType<P>;
  props?: Partial<P>;
};

export const getOverrides = <P extends RequiredPropsShape>(
  override: Override<P>,
  Component: React.ComponentType<P>,
  props: P
): [React.ComponentType<P>, P] => {
  // component override shortcut:
  if (typeof override === "function") {
    Component = override;
  } else if (override) {
    const {
      style,
      props: propsOverride,
      component,
      //...nested
    } = override;
    // assume at least one of the folllowing will override props, so clone
    props = { ...props };
    // component override:
    if (component) {
      Component = component;
    }
    // props override:
    if (propsOverride) {
      Object.assign(props, propsOverride);
    }
    // style override:
    if (style) {
      props.style = maybeMerge(props.style, typeof style === "function" ? style(props) : style);
      // TODO: support $style?
      // props.$style = style;
    }

    // nested overrides:
    // if (Object.keys(nested).length > 0) {
    //   props.overrides = maybeMerge(props.overrides, nested);
    // }
  }
  return [Component, props];
};

const createOverridenComponent = <P extends RequiredPropsShape>(
  name: string,
  Component: React.ComponentType<P>,
  getOverrideForRender: () => Override<P> | undefined
): React.ComponentType<P> => {
  const wrapped: React.ComponentType<P> = React.forwardRef<any, P>((props: P, ref: any) => {
    const override = getOverrideForRender();
    if (override) {
      const [NewComponent, mergedProps] = getOverrides(override, Component, props);
      return <NewComponent {...mergedProps} ref={ref} />;
    } else {
      return <Component {...props} ref={ref} />;
    }
  }) as any;

  wrapped.displayName = `${name}WithOverrides`;
  return wrapped;
};

export interface ComponentMap {
  [key: string]: React.ComponentType<any>;
}

export type Overrides<T extends ComponentMap> = {
  [K in keyof T]?: Override<React.ComponentProps<T[K]>>;
};

export const useOverrides = <T extends ComponentMap>(defaultComponents: T, overrides: Overrides<T> = {}): T => {
  const overridesRef = useRef(overrides);
  overridesRef.current = overrides;

  const x = useMemo(() => {
    return mapValues(defaultComponents, (value, name) =>
      createOverridenComponent(name, defaultComponents[name], () => overridesRef.current[name])
    );
  }, values(defaultComponents)); // eslint-disable-line react-hooks/exhaustive-deps

  return x as T;
};

/**
 * For a better DevTools inspection experience
 */
export const BasewebSelectVerbose: SelectOverrides = Object.assign(
  {},
  ...[
    "Root",
    "ControlContainer",
    "Placeholder",
    "ValueContainer",
    "SingleValue",
    "MultiValue",
    "Tag",
    "InputContainer",
    "Input",
    "IconsContainer",
    "SelectArrow",
    "ClearIcon",
    "LoadingIndicator",
    "SearchIconContainer",
    "SearchIcon",
    "Popover",
    "DropdownContainer",
    "Dropdown",
    "DropdownOption",
    "DropdownListItem",
    "OptionContent",
    "StatefulMenu",
  ].map((override) => ({ [override]: { props: { "data-baseweb-id": override } } }))
);
